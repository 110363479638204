import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import ContactForm from "../components/contact-form"

const Home = (props) => {

    return (
        <Layout css_class="contact">
            <Head title="Contact Us" />
            <main>
                <div className="container">
                    <section>
                        <ContactForm action="https://www.flexyform.com/f/YouFormKey"></ContactForm>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default Home