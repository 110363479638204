import React, { useState } from "react"
import axios from "axios"

import * as contactStyles from '../styles/modules/contact.module.scss';

const MyForm = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    })

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        })

        if (ok) {
            form.reset()
        }
    }
    const handleOnSubmit = e => {
        e.preventDefault()

        const form = e.target

        setServerState({ submitting: true })
        axios({
            method: "post",
            url: "https://api.kitchenwareuk.co.uk/contact",
            data: { name: name, email: email, message: message },
        })
            .then(r => {
                handleServerResponse(true, "Thank you for your email!", form)
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form)
            })
    }

    return (
        <div>
            <div className={contactStyles.contactForm}>
                <div className="row">
                    <div className="col-6">
                        <h3>Contact Us</h3>
                        <form onSubmit={handleOnSubmit}>
                            <div className={contactStyles.formGroup}>
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    required="required"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </div>
                            <div className={contactStyles.formGroup}>
                                <label required="required">Email address</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </div>
                            <div className={contactStyles.formGroup}>
                                <label>Message</label>
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={event => setMessage(event.target.value)}
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={serverState.submitting}
                            >
                                Submit
                            </button>
                            {serverState.status && (
                                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                                    {serverState.status.msg}
                                </p>
                            )}
                        </form>
                    </div>
                    <div className="col-6">
                        <h3>Visit Us</h3>
                        <p>Want to speak to someone? Our Customer Service team are happy to help. Drop us a note and we'll get back to you within 5 working days.
Delays may occur in response to any measures put in place to fight the current COVID-19 outbreak.</p>
                        <ul>
                            <li>Address: 3 St. Beavans Road, Halifax, West Yorkshire, HX3ORT, UK</li>
                            <li>info@kitchenwareuk.co.uk</li>
                        </ul>
                        <h3>Working Hours</h3>
                        <p>Monday - Saturday 08AM - 10PM</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyForm
